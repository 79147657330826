export default function useFormValidation (v$: any, submitted: any) {
  const validateState = (name: any) => {
    const { $dirty, $error } = v$.value[name]

    if (submitted.value) {
      return $dirty ? !$error : null
    }

    return null
  }

  return {
    validateState
  }
}
